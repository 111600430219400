import React from 'react'

function Home() {
  return (
    <div className='bg-gray-900'>
      <div className='main h-screen w-screen'>
        <div className='flex items-center pt-5'>
          <div className='headshot rounded-full'>
            <img src='isaac-pic.jpeg' alt='Isaac' className='rounded-full mr-4 ml-5 w-16 h-16 md:w-48 md:h-48'/>
          </div>
          <h1 className='text-3xl md:text-5xl font-bold text-white'>Isaac Rosenberg</h1>
        </div>
        <div className='mt-40'>
          <h2 className='text-3xl md:text-5xl font-bold text-center text-white'>Real Estate Tech Entrepreneur</h2>
        </div>
      </div>
      <div className='description flex-col flex justify-center items-center h-screen w-screen'>
        <h1 className='text-white text-3xl md:text-5xl font-bold mx-auto text-center my-8'>I Build Technology to Streamline Real Estate Development</h1>
        <div className='flex'>
           <img className='w-1/2 h-full object-cover' src='construction.jpg' alt=''/>
           <img className='w-1/2 object-contain' src='circuits.jpg' alt=''/>
        </div>
      </div>
      <div className='streetlight flex h-screen w-screen flex-col'>
        <div className='heading ml-10 pt-5'>
          <img src='streetlight.png' className='w-72 pr-4' alt=''/>
          <h2 className='text-white text-3xl font-semibold pl-3'>Co-Founder, CEO</h2>
          <h2 className='text-white text-3xl font-semibold pl-3'>October 2020 - March 2023</h2>
        </div>
        <h1 className='text-3xl md:text-5xl font-bold text-white text-center w-3/4 mx-auto mt-40'>I built a video app that streamlined housing entitlements in San Francisco</h1>
        <div className='flex justify-center mt-10'>
          <a href='/streetlight' className='bg-yellow-500 text-3xl text-white px-4 py-2 rounded-md w-60 flex items-center'>
            <span>Learn How</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-auto">
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
            </svg>
          </a>
        </div>
      </div>
      <div className='orgs pl-10 h-screen w-screen'>
        <h1 className='text-5xl font-bold text-left drop-shadow-lg text-white '>Proud to be part of these Great Organizations</h1>
        <img className='w-72 h-auto object-contain pt-10 pb-5' src='ULI-LA.png' alt=''/>
        <h2 className='text-lg font-bold text-left drop-shadow-lg text-white'>Innovation Council</h2>
        <h2 className='text-md font-bold text-left drop-shadow-lg text-white'>Member</h2>
        <img className='w-72 h-auto pt-20 pb-5 object-contain' src='REC_PN.webp' alt=''/>
        <h2 className='text-md font-bold text-left drop-shadow-lg text-white'>Cabinet Member</h2>
      </div>
      <div className='contact relative pl-10 h-screen w-screen'>
        <h1 className='text-5xl font-bold text-left drop-shadow-lg text-white pt-40'>Let's connect.</h1>
        <div className='pt-20'>
          <a href='mailto:isaac@skyscraper.dev' className='text-left text-2xl text-white font-semibold underline'>Email</a>
        </div>
        <div className='pt-10'><a className='text-left text-2xl text-white font-semibold underline' href='https://twitter.com/irosenb'>Twitter</a></div>
        <div className='pt-10'><a className='text-left text-2xl text-white font-semibold underline' href='https://linkedin.com/in/irosenb'>Linkedin</a></div>
        <footer className='absolute bottom-0'>
          <p className='text-white ml-10 drop-shadow-sm'>© 2023 Isaac Rosenberg</p>
          <p className='text-white ml-10 pt-1 pb-4 drop-shadow-sm'>If I'm a painting, I'm nowhere near done.</p>
        </footer>
      </div>
    </div>
  )
}

export default Home
