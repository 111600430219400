import React from 'react';

function Streetlight() {
  return (
    <div className='bg-yellow-500'>
      <div className='bg-yellow-500 h-screen w-screen'>
        <div className='heading ml-10 pt-5'>
          <h1 className='text-4xl md:text-5xl font-bold text-white'>Streetlight</h1>
          <h2 className='text-white text-2xl md:text-3xl font-semibold'>Co-Founder, CEO</h2>
          <h2 className='text-white text-2xl md:text-3xl font-semibold'>October 2020 - March 2023</h2>
        </div>
        <h1 className='text-4xl md:text-5xl font-bold text-white text-center w-3/4 mx-auto mt-40'>Video app for real estate entitlements, allowing residents to support new housing by recording videos.</h1>
      </div>
      <div className='product h-screen w-screen pl-10'>
        <h1 className='text-4xl md:text-5xl font-bold text-white text-center w-3/4 mx-auto mt-40'>How it Works</h1>
        <p className='text-2xl mt-10 text-white font-semibold'>1. Send your supporters a <a target='_blank' className='underline' href='https://streetlight.app/projects/560Brannan'>link</a> to record a video.</p>
        <p className='text-2xl text-white font-semibold'>2. Your supporter records a video.</p>
        <p className='text-2xl text-white font-semibold'>3. We compile these videos into a password-protected link you can share with the relevant city stakeholders.</p>
      </div>
      <div className='results md:h-screen w-screen'>
        <h2 className='text-white text-3xl md:text-5xl font-bold text-center w-3/4 mx-auto'>Streetlight Worked on 20+ Entitled Developments in San Francisco</h2>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-3 mt-20 pl-10 pr-10'>
          <img src='developments/one-oak-2.jpeg' alt='' className='w-full h-full rounded-md object-cover'/>
          <img src='developments/600-mcallister.jpeg' alt='' className='w-full h-full rounded-md object-cover'/>
          <img src='developments/560brannan.jpeg' alt='' className='w-full pb-10 h-full rounded-md object-cover'/>
        </div>
      </div>
      <div className='contract h-screen w-screen'>
        <h1 className='text-white text-3xl md:text-5xl font-bold text-center'>Business Development</h1>
        <h2 className='text-white text-2xl md:text-3xl font-semibold text-center pt-20 w-3/4 mx-auto'>Streetlight reached contract stage with a $1B+ real estate development in Los Angeles to use our software in their entitlements process.</h2>
      </div>
      <div className='bizdev bg-white pt-10 md:pt-10 h-screen w-screen'>
        <h2 className='text-yellow-500 text-3xl md:text-5xl font-bold text-center w-3/4 mx-auto'>Engaged Every Level of Real Estate, from Fortune 500s to Small Non-Profit Developers.</h2>
        <div className='grid grid-cols-3 md:grid-cols-6 gap-3 mt-20'>
          <div className='flex items-center justify-center'><img className='w-32 h-auto' src='logos/morganstanley.jpeg' alt=''/></div>
          <div className='flex items-center justify-center'><img className='w-32 h-auto' src='logos/hackman-logo.png' alt=''/></div>
          <div className='flex items-center justify-center'><img className='w-32 h-auto' src='logos/oaklandAs.svg' alt=''/></div>
          <div className='flex items-center justify-center'><img className='w-32 h-auto' src='logos/picerne-group.jpeg' alt=''/></div>
          <div className='flex items-center justify-center'><img className='w-32 h-auto' src='logos/whchc-logo.svg' alt=''/></div>
          <div className='flex items-center justify-center'><img className='w-32 h-auto' src='logos/cbre-logo.png' alt=''/></div>
        </div>
      </div>
    </div>
  )
}

export default Streetlight
