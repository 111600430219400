import { useEffect } from 'react'
import logo from './logo.svg';
import './App.css';
import Home from './home.js';
import Streetlight from './streetlight.js';

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

import ReactGA from 'react-ga';

function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/streetlight' element={<Streetlight/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
